import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    function refresh_work_package_list(t) {
      const project_id = t.target.value;
      const fill_work_package_url = '/person/backfill_hour_applies/fill_work_package.js';
      $.ajax(fill_work_package_url, {
        data: { project_id },
        dataType: 'script'
      });
    }

    $('#plm-project-select').on('change', refresh_work_package_list);
  }

  disconnect() {
    $('#plm-project-select').off('change');
  }

  add_detail(e) {
    const add_detail_work_package_url = '/person/backfill_hour_applies/add_detail.js';
    const project_id = $('#plm-project-select').val();
    const work_package_id = $('#plm-work-package-select').val();

    $.ajax(add_detail_work_package_url, {
      data: {
        project_id,
        work_package_id
      },
      dataType: 'script'
    });
    e.preventDefault();
  }
}
